.sidebar__reviews {
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid #DFDFDF;

    .reviews__block {
        border-top: 1px solid #EAEAEA;

        &:last-child {
            border-bottom: 1px solid #EAEAEA;
        }

        .rating {
            height: max-content;
        }

        .info {
            p:last-child {
                color: #888888;
            }
        }
    }
}
