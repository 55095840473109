.edit__address__section{
   .company__name{
    max-width: 85%;

   }
    .selected{
        border: 2px solid #9BC87D;
        background-color: #9BC87D1A;
    }
    .unselected{
        border: 1px solid #DFDFDF;

    }
    .save__button{
        width: 200px;
        height: 50px;
    }
    .select__text{
        color:#9BC87D;
    }

}


@media screen and (min-width: 1025px) {
    .edit__address__section{
        
        .relative{
            width: 100%;
            max-width:330px ;
        }
    }
  }
  @media screen and (min-width: 1250px) {
    .edit__address__section{
        
        .relative{
            width: 100%;
            max-width:365px ;
        }
    }
  }