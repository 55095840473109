.modelBox.megaAllMenu {
    .body {
        width: 100%;
        max-width: 480px;
        min-width: auto;
    }
    .megamenu .list{
        .menuList{
            a:last-child{
                margin-bottom: 50px;
            }
        }
    }
}

.megamenu {
    .menuCol {
        padding: 0px;
        position: relative;
        width: 100%;
        height: 100%;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 100%;
            background-color: #dfdfdf;
            z-index: 1;
        }
        &.false{
            display: none;
        }
        &.closing{
            display: block;
        }
        &.opening{
            display: block;
            .menuContent {
                transform: translateX(0%);
            }
        }
        &.open{
            display: block;
            .menuContent {
                transform: translateX(0%);
            }
        }
        &:nth-child(1) {
            z-index: 1;
        }
        &:nth-child(2) {
            z-index: 2;
            position: absolute;
        }
        &:nth-child(3) {
            z-index: 3;
            position: absolute;
        }
        &:last-child {
            .menuContent {
                box-shadow: 0px 0px 20px #00000033;
            }
        }
        .menuContent {
            background-color: #FFFFFF;
            padding-top: 12px;
            padding-bottom:2.5rem;
            transition: transform 0.2s cubic-bezier(.4,0,.6,1);
            transform: translateX(-100%);
            .backIcon {
                position: absolute;
                top: 16px;
                left: 24px;
            }
            .close {
                min-width: 38px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
                position: absolute;
                padding: 4px;
                margin: 0;
                top: 16px;
                right: 24px;
                cursor: pointer;
                color: #cccccc;
                &:hover {
                    color: #222222;
                }
            }
            .head {
                width: 100%;
            }
            .list {
                width: 100%;
                .menuList {
                    a {
                        display: block;
                        width: 100%;
                        position: relative;
                        &:hover {
                            background-color: #ebebeb !important;
                        }
                    }
                    .MuiButton-root {
                        font-family: "poppins";
                        border-radius: 0;
                        padding-left: 32px;
                        padding-right: 32px;
                        & > div {
                            min-height: 38px;
                            font-weight: 300;
                        }
                        text-transform: none;
                        .name {
                            line-height: 1.5;
                            font-size: 14px;
                            color: #222222;
                            width: 100%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                           
                        }
                        &:hover, &.true {
                            background-color: #ebebeb;
                        }
                        .nav {
                            .right-arrow {
                                width:8px;
                                height:8px;
                                border: 2px solid #222222;
                                border-left-width: 0;
                                border-bottom-width: 0;
                                border-radius: 2px;
                                transform: rotate(45deg);
                            }
                        }
                        .chip span{
                            display: flex;
                            font-size: 12px;
                            border-radius: 12px;
                            background: #9BC87D padding-box;
                            color: #FFFFFF;
                            padding: 6px 12px 5px 12px;
                            line-height: 12px;
                            white-space: nowrap;
                        }
                        .img {
                            font-size: 26px;
                            line-height: 1;
                            color: #222222;
                            min-width: 30px;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
            .menu_image {
                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }
            }
        }
        &.main {
            .menuContent {
                transform: translateX(0)!important;
            }
        }
        .head {
            width: 100%;
            padding-left: 32px;
            padding-right: 32px;
            min-height: 72px;
            overflow: hidden;
            h3 {
                font-size: 20px;
                font-weight: bold;
                line-height: 35px;
                min-height: 35px;
                white-space: nowrap;
                border-bottom: 1px solid #dfdfdf;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
            }
        }
        &.main {
            .name {
                font-weight: 700;
            }
        }
    }
}

// sidebar width adjustment in mobile devices
@media(max-width: 550px){
    .modelBox.megaAllMenu {
        .body {
            max-width: 90%;
        }
    }
}

/** large screen **/
@media screen and (min-width:1025px){
    .modelBox.megaAllMenu {
        .body {
            width: auto;
            max-width: none;
        }
    }
    .megamenu {
        .menuCol {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 100%;
                background-color: #dfdfdf;
                z-index: 1;
            }
            &.false {
                display: none;
            }
            &.closing{
                display: block;
            }
            &.opening{
                display: block;
                .menuContent {
                    transform: translateX(0%);
                }
            }
            &.open{
                display: block;
                .menuContent {
                    transform: translateX(0%);
                }
            }
            &:nth-child(1) {
                z-index: 3;
                .menuContent {
                    .list {
                        width: 400px;
                    }
                    .head {
                        width: 400px;
                    }
                }
            }
            &:nth-child(2) {
                z-index: 2;
                position: relative;
            }
            &:nth-child(3) {
                z-index: 1;
                position: relative;
            }
            &:last-child {
                .menuContent {
                    box-shadow: 0px 0px 20px #00000033;
                }
            }
            .menuContent {
                background-color: #FFFFFF;
                padding-top: 12px;
                padding-bottom: 12px;
                transition: transform 0.2s cubic-bezier(.4,0,.6,1);
                transform: translateX(-100%);
                .head {
                    width: 350px;
                }
                .list {
                    width: 350px;
                }
            }
        }
    }
}

/** extera large screen **/
@media screen and (min-width:1250px) {
    .megamenu {
        .menuCol {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 100%;
                background-color: #dfdfdf;
                z-index: 1;
            }
            &.false {
                display: none;
            }
            &.closing{
                display: block;
            }
            &.opening{
                display: block;
                .menuContent {
                    transform: translateX(0%);
                }
            }
            &.open{
                display: block;
                .menuContent {
                    transform: translateX(0%);
                }
            }
            &:nth-child(1) {
                z-index: 3;
                .menuContent {
                    .list {
                        width: 400px;
                    }
                    .head {
                        width: 400px;
                    }
                }
            }
            &:nth-child(2) {
                z-index: 2;
            }
            &:nth-child(3) {
                z-index: 1;
            }
            &:last-child {
                .menuContent {
                    box-shadow: 0px 0px 20px #00000033;
                }
            }
            .menuContent {
                background-color: #FFFFFF;
                padding-top: 12px;
                padding-bottom: 12px;
                transition: transform 0.2s cubic-bezier(.4,0,.6,1);
                transform: translateX(-100%);
                .head {
                    width: 350px;
                }
                .list {
                    width: 350px;
                }
            }
        }
    }
}