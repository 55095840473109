.upload__info {
    background-color: #FFF;

        .deliveryTime {
            background-color: #FFF;
            h1 {
    font-size: 26px;
    padding: 1rem 0;
            }
        }
        .delivery-custom-message{
            h1 {
                font-size: 20px;
                padding: 1rem 0;
                font-weight: 700;
                line-height: 1.75rem;
                        }

                        h2{
                            font-size: 18px;
                            padding: 1rem 0;
                            font-weight: 700;
                            line-height: 1.75rem;
                       }

                       p{
                        line-height: 1.75rem  !important;
                        font-weight: 300 !important;
                       }
        }
}