.productTitle{
    h1 {
        font-weight: bold;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: 0.59px;
    }
    p,.info {
        font-weight: 300;
        font-size: 15px;
        line-height: 23px;
    }
    .stockStatus {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        //commented for purpose
        // &.true {
        //     background-color: #9BC87D;
        // }
    }
    .in-stock {
        background-color: #9BC87D;
    }

    .out-of-stock{
        background-color: #d80000;
    }
    .MuiRating-iconEmpty{
        color: #D7D7D7;
        svg {
            fill: #D7D7D7;
        }
    }
    a, button {
        text-decoration: underline;
        &:hover {
            color: var(--themeColor);
        }
    }
    .sku__pdp{
        word-break: break-all;
    }
}