.shorooms {
    .page__container {
        .showroom__image{
            max-width: 1000px;
            margin: auto;
        }
        .showroom__info__wrapper{
            max-width: 800px;
            margin: auto;
        }
        .timings{
            .flex{
                p:first-child{
                    width: 90px;
                }
            }
        }
        img{
            border-radius: 10px;
        }

        .contact{
            img{
                width: 13px;
                height: 13px;
                border-radius: 5px;
            }
        }
    }
}