.sidebar__minicart {
  background-color: #fff;
  width: 100%;
  max-width: 100%;

  .span__text {
    a {
      text-decoration: underline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      min-width: 250px;
      max-width: 260px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
    p{
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: normal;
        min-width: 250px;
        max-width: 260px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
  }

  .sidebar__heading {
    h1 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }

  .product__img a {
    width: 70px;
    height: 70px;
    background-color: #F5F5F5;
    min-width: 70px;
    border-radius: 10px;
    padding: 0.5rem;
    img {
      object-fit: contain;
      mix-blend-mode: multiply;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .minicart__footer {
    background-color: #fff;

    .priceDetails__block {
      background-color: #f5f5f5;

      .priceDetails {
        width: 80%;

        table {
          width: 100%;

          tr {
            line-height: 1.5rem;

            .green {
              color: #43a700;
            }

            td {
              font-size: 14px;
            }

            td:nth-child(2) {
              text-align: right;
            }
          }
        }
      }
    }

    .action__blocks {
      .payment__img {
        width: 85%;
        height: 50px;
      }

      button {
        height: 46px;
      }

      .cart__button {
        color: #222;
        background-color: #ffcc00;

        &:hover {
          background-color: #f1c000;
        }
      }

      .quote__button {
        background-color: #222222;

        &:hover {
          background-color: var(--themeColor);
        }
      }
    }
  }

  .details__block {
    .up__arrow {
      svg {
        width: 18px;
        height: 18px;
      }
    }

    .stockprice {
      font-size: 15px;
    }

    .delete__img {
      width: 16px;
      height: 18px;
      cursor: pointer;
    }
    .input__container_loader {
      width: 115px;
      border: 1px solid #dfdfdf;
      height: 42px;
      background-color: #fff;
      border-radius: 5px;

    }
    .input__container {
      width: 115px;
      align-items: center;
      border: 1px solid #dfdfdf;
      height: 42px;
      background-color: #fff;
      border-radius: 5px;

      input {
        width: 50px;
        text-align: center;
        height: 40px;
        background: #fff;
        padding: 0px;
      }

      button {
        width: 30px;
        height: 40px;
        background-color: #fff;
        color: #222222;
        border: none;
        cursor: pointer;
      }
    }
  }

  .cart__details {
    .best {
      p {
        display: block !important;
      }
    }

    >div {
      border-top: 1px solid #dfdfdf;

      .mail__text {
        text-decoration: underline;
      }
    }

    &>div:nth-child(4) {
      border-bottom: 0;
    }

    .social__detail {
      a {
        justify-content: flex-start;
      }

      h3 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .default_cart__details>div {
    border-top: 1px solid #dfdfdf !important;
    padding-left: 0px !important;
  }

  .vat_font {
    font-size: 16px !important;
    font-weight: bold;
  }

  .upload_file_mini_cart {
        display: flex;
        flex-direction: column;
        gap: 11px;
        margin: 10px 0;
  }
}

/** small screen **/
@media screen and (min-width: 520px) {
  .sidebar__minicart {
    padding: 0px 1rem;

    .stockprice {
      font-size: 11px;
    }
  }
}

/** small screen **/
@media screen and (max-width: 430px) {
  .sidebar__minicart {
    .details__block {
      .stockprice {
        font-size: 12px !important;
      }
     
    }
    .priceFinal{
      .fs-20{
        font-size: 18px;
      }
    }

    .input__container {
      input {
        width: 40px !important;
      }

      button {
        width: 25px !important;
      }
    }
  }


  @media screen and (max-width: 480px) {
    .stockprice {
      white-space: nowrap;
      position: absolute;
      top: 15px;
    }

    .delete__img {
      position: absolute;
      // left: 190px;
      left: 210px;
    }
  }

  @media screen and (max-width: 360px) {

    .sidebar__minicart,
    .default_cart__details {
      padding-left: 0px;
      padding-right: 0px;
    }

    .default_cart__details {
      padding-left: 10px;
      padding-right: 0px;
    }
  }

  .default_cart__details {
    overflow-y: scroll;
  }

  .delete__img img {
    max-width: unset !important;
    width: 14px !important;
  }
}