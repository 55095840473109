.features__section {
    .hoverLink{
        &:hover{
            text-decoration: underline;
        }
    }
    .sliderLeftRightVisible {
        align-items: center;
        &::before,
        &::after {
            opacity: 0;
        }
    }

    .keen-slider {
        overflow: hidden !important;
    }

    .socialicon__img {
        width: 38px;
        height: 38px;
    }

    .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
        min-height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
    }

    background-color: #f5ede7;
    border-radius: 10px;
    min-height: 80px;
}

.keen-slider.sliderLeftRightVisible.plp2.features__details {
    padding-top: 10px;

    .feature__details {
        width: 100%;
        gap: 50px;

        .details {
            column-gap: 15px;
            margin-top: -5px;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: 20px;

            .image__block {
                width: 100px;
                min-width: 100px;
                height: 100px;
                min-height: 100px;
                position: relative;

                .socialicon__img {
                    position: absolute;
                    width: 100px;
                    min-width: 100px;
                    height: 100px;
                    min-height: 100px;
                    top: -5px;
                }
            }

            &>.flex {

                &.title__image {
                    h3 {
                        padding-top: 25px;
                        padding-bottom: 15px;
                    }
                    .social__details{
                        display: none;
                    }
                }
            }

            .social__details {
                flex-grow: 1;
            }
        }
    }
}

@media (min-width: 1025px) {
    .features__section {
        .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
            min-width: 50%;
        }
    }
}

@media (min-width: 768px) {
    .features__section {
        .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
            min-width: 50%;
        }
    }
}

@media (min-width: 320px)  {
    .keen-slider.sliderLeftRightVisible.plp2.features__details .feature__details .details .social__details.noView{
        display: none;
    }
}
@media (min-width: 320px) and (max-width: 768px) {
    .keen-slider.sliderLeftRightVisible.plp2.features__details .feature__details .details > .flex.title__image .social__details.view{
        display: block;
    }
    .keen-slider.sliderLeftRightVisible.plp2.features__details .feature__details .details > .flex.title__image{
        position: relative;
        .social__details__name{
            h3{
                width: calc(100% - 125px);
            }
        }
        .image__block{
            position: absolute;
            right: 16px;
        }
    }
}
@media (min-width: 768px) {
    .keen-slider.sliderLeftRightVisible.plp2.features__details .feature__details .details > .flex.title__image .social__details__name,
    .keen-slider.sliderLeftRightVisible.plp2.features__details .feature__details .details > .flex.title__image .social__details.view{
        display: none;
    }
}

@media (max-width: 768px) {

    .features__section {
        .feature__details>div {
            min-width: 100% !important;
            justify-content: center;
        }

    }
}

@media(min-width: 420px) {
    .keen-slider.sliderLeftRightVisible.plp2.features__details {
        .feature__details {
            .details {
                .title__image{
                    gap: 25px;
                }
            }
        }
    }
}

@media(min-width: 768px) {
    .keen-slider.sliderLeftRightVisible.plp2.features__details {
        padding-top: 0px;
    
        .feature__details {
            width: 100%;
            gap: 50px;
    
            .details {
                column-gap: 15px;
                margin-top: 0px;
                justify-content: center;
                flex-wrap: wrap;
                padding-top: 20px;
                padding-bottom: 20px;
                max-width: 100%;
                align-items: center;
    
                .image__block {
                    width: 51px;
                    min-width: 51px;
                    height: 51px;
                    min-height: 51px;
                    position: relative;
    
                    .socialicon__img {
                        position: absolute;
                        width: 51px;
                        min-width: 51px;
                        height: 51px;
                        min-height: 51px;
                        top: 0px;
                    }
                }
    
                &>.flex {
                    &.title__image {
                        flex-direction: row;
                        gap: 18px;
                        .social__details{
                            display: block;
                        }
                        h3 {
                            padding-bottom: 5px;
                            padding-top: 0px;
                            white-space: nowrap;
                        }
                    }
                }
    
                .social__details {
                    flex-wrap: 1;
                }
            }
        }
    }
}