.terms__conditions {
    .page__container {
        button {
            height: 50px;
            svg{
                min-width: 15px;
            }
        }

        a {
            color: var(--themeColor);
            font-weight: 300;
        }
        img{
            width: 15px;
            height: 16px;
        }
    }
}