.authentication__container {
    .authentication__wrapper {
        width: 100%;

        .auth__wrapper {
            .form__block {
                form {
                    .column__wrapper {
                        width: 100%;
                        min-width: 100%;

                        .input__control .validation__icon {
                            right: 33px;
                        }

                        input[type="checkbox"],
                        input[type="radio"] {
                            padding: 0px;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            width: 25px;
                            height: 25px;
                            border: 1px solid #F5F5F5;
                            border-radius: 50%;
                            outline: none;
                            cursor: pointer;
                            background-color: #F5F5F5;
                            transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
                        }

                        input[type="checkbox"] {
                            border-radius: 5px;
                        }

                        input[type="checkbox"]:checked {
                            border: 3px solid #DFDFDF;
                            background-color: var(--themeColor);
                        }

                        input[type="radio"]:checked {
                            border: 4px solid #F5F5F5;
                            background-color: var(--themeColor);
                        }
                    }
                }
            }
        }
    }
}

.authentication__container.register {
    .authentication__wrapper {
        .auth__wrapper {
            .form__block {
                form {
                    .action__block {
                        width: 910px;

                        &:after {
                            content: unset;
                        }
                    }
                }
            }
        }
    }
}

/** medium screen **/
@media screen and (min-width:768px) {
    .authentication__container {
        .authentication__wrapper {
            width: 80%;
        }
    }
}

/** large screen **/
@media screen and (min-width:1025px) {
    .authentication__container {
        .authentication__wrapper {
            width: 942px;

            .auth__wrapper {
                .form__block {
                    form {
                        .column__wrapper {
                            width: 440px;
                            min-width: 440px;
                            position: relative;

                        }

                        input[type="text"],
                        input[type="password"],
                        input[type="email"] {
                            width: 365px;
                        }


                        .column__wrapper::after {
                            right: -20px;
                        }

                        button,
                        a {
                            font-weight: 700;
                        }
                    }
                }

                &.reset__password {

                    h1 {
                        font-size: 24px;
                    }

                    .form__block {
                        form {
                            .column__wrapper {
                                button {
                                    width: 280px;
                                    min-width: 280px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .authentication__container.register {
        .authentication__wrapper {
            .auth__wrapper {
                .form__block {
                    form {
                        .fields__wrapper>div:first-child::after {
                            content: "";
                            position: absolute;
                            top: 8px;
                            bottom: 0;
                            width: 1px;
                            background-color: #DFDFDF;
                        }
                    }
                }
            }
        }
    }
}