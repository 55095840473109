.mydetails__sidebar {
    .details__edit__form {
        background-color: #fff;
        width: 100%;
        max-width: 100%;
        height: 100%;
        form{
            .email__field{
                .error__border {
                    border: 0px solid #e44061;
                    outline: unset;
                }
                .field__error{
                    margin-top: -18px;
                    text-align: left;
                    color: #666666;
                }
                input{
                    color: #CCCCCC;
                }
            }
        }
        .action__block {
            button{
                min-height: 40px;
                &:last-child{
                    border: 1px solid #ffffff !important;
                    &:hover {
                        border: 1px solid #000000 !important;
                        background-color: unset !important;
                        color: #000000;
                    }
                }
            }
        }
    }
}