@import url(./indexColor.scss);

$container-width:1284px;
$rem:0.25;
$loop-size:24;

html {
  font-family:"poppins", Helvetica, Arial, sans-serif; font-size:16px; font-weight:300;font-display: swap; -ms-text-size-adjust:100%; -webkit-text-size-adjust:100%; position:relative;
}
body {
  font-size:16px; font-weight:300; line-height:1.3125; letter-spacing:0.022em; font-family:"poppins", Helvetica, Arial, sans-serif;font-display: swap; background-color:#ffffff; color:#222222; font-style:normal;
}
*, *::before, *::after { box-sizing:border-box;}
abbr,blockquote,body,button,dd,dl,dt,fieldset,figure,form,h1,h2,h3,h4,h5,h6,hgroup,input,legend,li,ol,p,pre,ul {margin:0; padding:0;}
address,caption,code,figcaption,pre,th { font-size:1em; font-weight:400; font-style:normal;}
fieldset, iframe { border:0;}
table { border-collapse:collapse; border-spacing:0;}
details,main,summary { display:block;}
audio,canvas,progress,video {vertical-align:baseline;}
::-moz-focus-inner {border:0; padding:0;}
input::-ms-clear { display:none;}
button,input,optgroup,select,textarea {color:inherit; font-family:inherit; font-size:100%; font-weight:inherit; line-height:inherit; margin:0; padding:0;}
[multiple],[type="date"],[type="datetime-local"],[type="email"],[type="month"],[type="number"],[type="password"],[type="search"],[type="tel"],[type="text"],[type="time"],[type="url"],[type="week"],select,textarea {
  -webkit-appearance:none;-moz-appearance:none;appearance:none;background-color:#F5F5F5;border-radius:5px;font-size:1rem;line-height:1.5rem;padding:0.5rem 0.75rem;border:0;height:50px;
}
[multiple]:focus,[type="date"]:focus,[type="datetime-local"]:focus,[type="email"]:focus,[type="month"]:focus,[type="number"]:focus,[type="password"]:focus,[type="search"]:focus,[type="tel"]:focus,[type="text"]:focus,[type="time"]:focus,[type="url"]:focus,[type="week"]:focus,select:focus,textarea:focus {
  outline:0px solid transparent;outline-offset:1px;
}
input[type="number"] { -moz-appearance:textfield;}
input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {-webkit-appearance:none;margin:0;}
body,button,input,select,textarea {font-synthesis:none;-moz-font-feature-settings:"kern";-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;direction:ltr;text-align:left;}
b,strong {font-weight:600;}
cite,dfn,em,i {font-style:italic;}
h1,h2,h3,h4,h5,h6 {margin:0;font-size:inherit;font-weight:inherit;}
ul,ol {list-style:none;margin:0;padding:0;}
li {margin:0;padding:0;}
a {display:inline-flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;-webkit-tap-highlight-color:transparent;outline:0px;border:0px;margin:0px;cursor:pointer;user-select:none;vertical-align:middle;appearance:none;text-decoration:none;font-family:inherit;font-weight:300;font-size:inherit;line-height:inherit;letter-spacing:0.02857em;color:inherit;
  &:disabled {cursor:default;}
  &:focus {outline:0 solid transparent;outline-offset:1px;}
}
button a{
  margin: 1rem;
}
button {display:inline-flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;-webkit-tap-highlight-color:transparent;outline:0px;border:0px;margin:0px;cursor:pointer;user-select:none;appearance:none;text-decoration:none;font-family:inherit;font-weight:400;font-size:inherit;line-height:inherit;letter-spacing:0.02857em;padding:0;border-radius:2px;
  transition:all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color:inherit;background-color:inherit;box-shadow:none;
  &:disabled {cursor:not-allowed;}
  &:focus {outline:0 solid transparent;outline-offset:1px;}
}
.ellips {display:-webkit-box;-webkit-box-orient:vertical;text-overflow:ellipsis;overflow:hidden;
  &.line2 {-webkit-line-clamp:2;}
  &.line3 {-webkit-line-clamp:3;}
}
.container {max-width:$container-width;width:100%;margin:0 auto;}
.container-fluid {width:100%;margin:0 auto;}
.no-scrollbar {-ms-overflow-style:none;scrollbar-width:none;
  &::-webkit-scrollbar {display:none;}
}

@mixin common-css($prefix:"") {
  .#{$prefix}relative {position:relative;}
  .#{$prefix}absolute {position:absolute;}
  .#{$prefix}fixed {position:fixed;}
  .#{$prefix}sticky {position:sticky;}
  .#{$prefix}static {position:static;}
  .#{$prefix}tl {text-align:left;}
  .#{$prefix}tc {text-align:center;}
  .#{$prefix}tr {text-align:right;}
  .#{$prefix}tj {text-align:justify;}
  .#{$prefix}ellipsis {text-overflow:ellipsis;}
  .#{$prefix}uppercase {text-transform:uppercase;}
  .#{$prefix}capitalize {text-transform:capitalize;}
  .#{$prefix}fw-600 {font-weight:600;}
  .#{$prefix}fw-500 {font-weight:500;}
  .#{$prefix}fw-700 {font-weight:700;}
  .#{$prefix}fw-300 {font-weight:300;}
  .#{$prefix}fw-100 {font-weight:100;}
  .#{$prefix}fw-bold,.#{$prefix}b {font-weight:bold;}
  .#{$prefix}overflow-hidden {overflow:hidden;}
  .#{$prefix}overflow-x-hidden {overflow-x:hidden;}
  .#{$prefix}overflow-y-hidden {overflow-y:hidden;}
  .#{$prefix}overflow-auto {overflow:auto;}
  .#{$prefix}overflow-x-auto {overflow-x:auto;}
  .#{$prefix}overflow-y-auto {overflow-y:auto;}
  .#{$prefix}overflow-visible {overflow:visible;}
  .#{$prefix}overflow-x-visible {overflow-x:visible;}
  .#{$prefix}overflow-y-visible {overflow-y:visible;}
  .#{$prefix}v-hide {visibility:hidden;}
  .#{$prefix}v-show {visibility:visible;}
  .#{$prefix}text-underline {text-decoration:underline;}
  .#{$prefix}image-contain {object-fit:contain;}
  .#{$prefix}image-cover {object-fit:cover;}
  .#{$prefix}text-strike {text-decoration:line-through;}
  .#{$prefix}text-nowrap {white-space:nowrap;}
  .#{$prefix}text-wrap {white-space:normal;}
  .#{$prefix}pointer {cursor:pointer;}
  .#{$prefix}Cauto {cursor:auto;}
  [class^="ModelPopup-"], [class*=" ModelPopup-"]{overflow:hidden;}
  [class^="Mui"],[class*=" Mui"] {
    &.#{$prefix}hide{display:none;}
    &.#{$prefix}flex{display:flex;}
    &.#{$prefix}block{display:block;}
    &.#{$prefix}inline{display:inline;}
    &.#{$prefix}inline-block {display:inline-block;}
  }
  .#{$prefix}hide{display:none;}
  .#{$prefix}block{display:block;}
  .#{$prefix}inline{display:inline;}
  .#{$prefix}inline-block{display:inline-block;}
  .#{$prefix}mx-auto{margin-left:auto;margin-right:auto;}
  .#{$prefix}my-auto{margin-top:auto;margin-bottom:auto;}
  .#{$prefix}r-full{border-radius:9999px;}
  .#{$prefix}flex {
    display:flex;
    &.#{$prefix}wrap {flex-wrap:wrap;}
    &.#{$prefix}nowrap {flex-wrap:nowrap;}
    &.#{$prefix}row {flex-direction:row;}
    &.#{$prefix}col {flex-direction:column;}
    &.#{$prefix}row-i {flex-direction:row-reverse;}
    &.#{$prefix}col-i {flex-direction:column-reverse;}
    &.#{$prefix}center {justify-content:center;}
    &.#{$prefix}right {justify-content:flex-end;}
    &.#{$prefix}left {justify-content:flex-start;}
    &.#{$prefix}fillX {justify-content:stretch;}
    &.#{$prefix}top {align-items:flex-start;}
    &.#{$prefix}bottom {align-items:flex-end;}
    &.#{$prefix}middle {align-items:center;}
    &.#{$prefix}fillY {align-items:stretch;}
    &.#{$prefix}space-between {justify-content:space-between;}
    &.#{$prefix}space-around {justify-content:space-around;}
  }
  @for $i from 1 through 32 {
    $size-i:#{$i}px;
    .#{$prefix}fs-#{$i}{font-size:$size-i;}
  }
  @for $i from 0 through $loop-size {
    $size-i:#{$i * $rem}rem;
    .#{$prefix}line-#{$i} {line-height:$size-i;}
    .#{$prefix}w-1\/#{$i} {max-width:#{100% / $i};width:100%;}
    .#{$prefix}h-1\/#{$i} {max-height:#{100% / $i};height:100%;}
    .#{$prefix}min-w-1\/#{$i} {min-width:#{100% / $i};}
    .#{$prefix}min-h-1\/#{$i} {min-height:#{100% / $i};}
    .#{$prefix}top-#{$i} {top:$size-i;}
    .#{$prefix}left-#{$i} {left:$size-i;}
    .#{$prefix}right-#{$i} {right:$size-i;}
    .#{$prefix}bottom-#{$i} {bottom:$size-i;}
    .#{$prefix}p-#{$i} {padding:$size-i;}
    .#{$prefix}px-#{$i} {padding-left:$size-i;padding-right:$size-i;}
    .#{$prefix}py-#{$i} {padding-top:$size-i;padding-bottom:$size-i;}
    .#{$prefix}pl-#{$i} {padding-left:$size-i;}
    .#{$prefix}pr-#{$i} {padding-right:$size-i;}
    .#{$prefix}pt-#{$i} {padding-top:$size-i;}
    .#{$prefix}pb-#{$i} {padding-bottom:$size-i;}
    .#{$prefix}m-#{$i} {margin:$size-i;}
    .#{$prefix}mx-#{$i} {margin-left:$size-i;margin-right:$size-i;}
    .#{$prefix}my-#{$i} {margin-top:$size-i;margin-bottom:$size-i;}
    .#{$prefix}ml-#{$i} {margin-left:$size-i;}
    .#{$prefix}mr-#{$i} {margin-right:$size-i;}
    .#{$prefix}mt-#{$i} {margin-top:$size-i;}
    .#{$prefix}mb-#{$i} {margin-bottom:$size-i;}
    .#{$prefix}r-#{$i} {border-radius:$size-i;}
    .#{$prefix}rt-#{$i} {border-top-left-radius:$size-i;border-top-right-radius:$size-i;}
    .#{$prefix}rb-#{$i} {border-bottom-left-radius:$size-i;border-bottom-right-radius:$size-i;}
    .#{$prefix}rtl-#{$i} {border-top-left-radius:$size-i;}
    .#{$prefix}rtr-#{$i} {border-top-right-radius:$size-i;}
    .#{$prefix}rbl-#{$i} {border-bottom-left-radius:$size-i;}
    .#{$prefix}rbr-#{$i} {border-bottom-right-radius:$size-i;}
    .#{$prefix}zindex-#{$i} {z-index:$i;}
    .#{$prefix}flex {
      &.#{$prefix}gap-#{$i} {gap:$size-i;}
      &.#{$prefix}gap-x-#{$i} {column-gap:$size-i;}
      &.#{$prefix}gap-y-#{$i} {row-gap:$size-i;}
      &>.#{$prefix}flex-#{$i} {flex:$i 1 0%;}
    }
  }
}

.animate-pulse {animation:pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;}
.animate-wave {
  position:relative;
  display:block;
  background-color:rgba(0, 0, 0, 0.11);
  height:1.2em;
  position:relative;
  overflow:hidden;
  -webkit-mask-image:-webkit-radial-gradient(white, black);
}

.animate-wave::after {
  -webkit-animation:wave 0.7s linear 0.5s infinite;
  animation:wave 0.7s linear 0.5s infinite;
  background:linear-gradient(90deg,
      transparent,
      rgba(0, 0, 0, 0.04),
      rgba(255, 255, 255, 0.12),
      transparent);
  content:"";
  position:absolute;
  -webkit-transform:translateX(-100%);
  -moz-transform:translateX(-100%);
  -ms-transform:translateX(-100%);
  transform:translateX(-100%);
  bottom:0;
  left:0;
  right:0;
  top:0;
}
.animate-spinning {animation:spinning 0.8s cubic-bezier(0.4, 0, 0.6, 1);}
.animate-button-press {animation:button-press 0.8s cubic-bezier(0.4, 0, 0.6, 1);}
/*scroll bar */
::-webkit-scrollbar {width:8px;height:8px;}
::-webkit-scrollbar-track {border-radius:0;background-color:#ebebeb;}
::-webkit-scrollbar-thumb { border-radius:2px; background-color:rgba(0, 0, 0, 0.2);-webkit-box-shadow:inset 1px 1px 0px rgba(0, 0, 0, 0.1),inset 0px -1px 0px rgba(0, 0, 0, 0.07);}

@include common-css();

/** extra small screen **/
@media screen and (min-width:360px) {
  @include common-css(xs-);
}

/** small screen **/
@media screen and (min-width:420px) {
  @include common-css(sm-);
}

/** medium screen **/
@media screen and (min-width:640px) {
  @include common-css(md-);
}

/** tablet screen **/
@media screen and (min-width:768px) {
  @include common-css(lg-);
  .lg-pt-32\/2{padding-top:9.625rem;}
}

/** large screen **/
@media screen and (min-width:1025px) {
  @include common-css(xl-);
}

/** extera large screen **/
@media screen and (min-width:1250px) {
  @include common-css(xxl-);
}

@keyframes show {
  0% {
    opacity:0;
  }

  100% {
    opacity:1;
  }
}

@keyframes pulse {
  50% {
    opacity:0.35;
  }
}

@keyframes spinning {
  0% {
    transform:rotate3d(0.075, 1, -0.075, 0deg);
  }

  100% {
    transform:rotate3d(0.075, 1, -0.075, 360deg);
  }
}

@keyframes wave {
  0% {
    transform:translateX(-100%);
  }

  100% {
    transform:translateX(100%);
  }
}

@keyframes button-press {
  50% {
    transform:scale3d(0.85, 0.85, 0.85);
  }
}
@keyframes fadeInAnimation {
	from {
		opacity: 0;
		transform: translate3d(0, -80%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
@keyframes fadeOutAnimation {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	to {
		opacity: 0;
		transform: translate3d(0, -80%, 0);
	}
}