.aboutus {

  a{
    text-decoration: underline;
  }
  .about__img{
    width: 100%;
    height: 369px;
  }
  .main__section{
    background-color: #F5F5F5;
  }
  .promofit__header {
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    h1 {
      font-size: 80px;
      color: #fff;
    }
    
  }
}
@media screen and (min-width: 1024px) {
  .aboutus {
    .promofit__header {
      height: 480px;
    }
    
  }
}
@media screen and (min-width: 640px) {
  .aboutus {
    .aboutus__section__one {
      > div {
        display: block;
        margin: 0 auto;
        width: 80%;
    
        a {
          text-decoration: underline;
        }
      }
    }
    .about__img{
      width: 483px !important; 
    height: 369px;
        }
   .aboutus__section__two{
    div{
      width: 50%;
    }
   }
   .aboutus__section__one{
    a{
      text-decoration: underline;
      display: inline;
    }
    p{
      display: inline;
    }
    >div{
      display: block;
      margin: 0px auto;
      width: 50%;
  }
    
  }
  }
}
@media screen and (max-width:420px) {
  .promofit__header {
    height: 300px !important;
    h1 {
      font-size: 50px !important;
    }
  }
 }
