.product__grid {
  width: 292px;
  min-width: 292px;
  max-width: 292px;
  height: 449px;
  max-height: 449px;
  min-height: 449px;

  .product__block {
    width: 292px;

    .image__block {

      &.cardHover,
      &:hover {
        cursor: pointer;

        .wishedItem {
          display: block;
        }

        .colorList {
          .list {
            opacity: 1;
          }
        }
      }

      .wishedItem {
        display: none;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .colorList {
        width: 100%;
        left: 0;
        bottom: 8px;
        min-height: 40px;

        .list {
          width: 264px;
          margin: 0 auto;
          position: relative;
          opacity: 0;

          .pad {
            background-color: #fff;
            border-radius: 20px;
            padding: 8px 6px;
            width: 264px;
            overflow: hidden;
            position: relative;

            .content {
              width: 255px;
              overflow: hidden;
              position: relative;
              margin: auto;

              &.hideRightArrow {
                .slick-slider {
                  .slick-next {
                    opacity: 0;
                    z-index: 0;
                    visibility: hidden;
                  }
                }
              }

              &.hideLeftArrow {
                .slick-slider {
                  .slick-prev {
                    opacity: 0;
                    z-index: 0;
                    visibility: hidden;
                  }
                }
              }

              .slick-slider {


                .slick-arrow {
                  width: 24px;
                  height: 24px;
                  opacity: 1;
                  z-index: 1;
                  color: transparent;
                  outline: none;
                  background: transparent;

                  &.slick-disabled {
                    opacity: 0;
                    z-index: 0;
                  }
                }

                .slick-prev {
                  left: 2px;
                  opacity: 1;

                  &::before {
                    content: "";
                    background-image: url("../../Res/images/footer/rightArrow.webp");
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background-position: 100% 100%;
                    background-size: contain;
                    width: 24px;
                    height: 24px;
                    opacity: 1;
                  }
                }

                .slick-next {
                  right: 5px;
                  opacity: 1;

                  &::before {
                    transform: rotate(180deg);
                    content: "";
                    background-image: url("../../Res/images/footer/rightArrow.webp");
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    background-position: 100% 100%;
                    background-size: contain;
                    width: 24px;
                    height: 24px;
                    opacity: 1;
                  }
                }

                .slick-list {
                  width: calc(100% - 1px);

                  .slick-track {
                    .slick-slide {
                      &>div {
                        margin: 0px 2px;
                        line-height: 0px;

                        &>div {
                          .colorcircle {
                            border: 1px solid #e5e5e5;
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            cursor: pointer;

                            @-moz-document url-prefix() {
                              border-style: unset;
                            }
                          }


                          .colorcircle {
                            img {
                              border-radius: 50%;
                              width: 100%;
                              height: 100%;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.fullwidth {
          .list {
            width: max-content;

            .pad {
              width: max-content;

              .content {
                width: max-content;

                .slick-slider {
                  .slick-list {
                    width: calc(100% - 0px);

                    .slick-track {
                      display: contents;
                    }
                  }
                }
              }
            }
          }
        }
      }

      a {
        width: 292px;
        height: 332px;
        background-color: #f5f5f5;
        img {
          mix-blend-mode: multiply;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .tag__name {
        max-width: calc(100% - 54px);

        p {
          padding: 6px 12px;
          font-size: 14px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
    }

    .product__title {
      a {
        font-size: 15px;

        &:hover {
          text-decoration: underline;
        }
      }

    }

    .price__action__block {
      .price__block {
        .label {
          font-size: 14px;
        }

        .price {
          font-size: 30px;
          margin-bottom: -6px;
        }

        .special {
          color: #cc1414;
        }

        .price__strike {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .action__block {
        cursor: pointer;

        a {
          border: 1px solid #ffcc00;
          background-color: #ffcc00;
          padding: 0px 12px;
          font-size: 13px;
          min-height: 39px;
          width: 74px;
          line-height: 1;
          border-radius: 10px;
          white-space: nowrap;
          cursor: pointer;

          & * {
            cursor: pointer;
          }

          svg {
            font-size: 10px;
            transform: rotate(90deg);
          }

          &:hover {
            background-color: var(--themeColor);
            color: var(--themeColorHover);
            border: 1px solid var(--themeColor);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .product__grid {
    .product__block {
      .image__block {
        .wishedItem {
          display: block;
        }

        .colorList {
          .list {
            opacity: 1;
          }
        }
      }
    }
  }
}

.tooltip-text {
  
  font-family: 'Poppins', sans-serif;
}