.backgroundBox{
    background-color: #FFFFFF;
    &.userBox{
        background-color: #DBEDF1;
    }
    border-radius: 10px;
}

@media(min-width: 768px){
    .backgroundBox{
        border-radius: 20px;
    }
}