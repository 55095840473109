.productVariant {
    .variantOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.00);
    }

    .MuiAccordion-root {
        box-shadow: none;

        &.Mui-expanded {
            margin: 0;
        }

        &::before {
            display: none;
        }

        &>.MuiButtonBase-root {
            padding-left: 0;
            padding-right: 0;
            min-height: auto;

            & .MuiAccordionSummary-content {
                margin: 12px 0;
            }
        }

        & .MuiAccordionDetails-root {
            padding: 0;

            .field__block .label {
                cursor: pointer;
            }
        }

        .variantHeader {
            font-size: 20px;
            font-weight: bold;
            line-height: 30px;

            &>span {
                min-height: 30px;
                text-overflow: ellipsis;
                line-height: 30px;
            }

            i {
                font: normal normal 300 15px/30px Poppins;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 80px;
            }

            .show {
                width: 32px;
                height: 32px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                svg {
                    width: 20px;
                    height: 20px;
                    color: #707070
                }
            }

            .status {
                width: 32px;
                height: 32px;
                border: 1px solid #222222;
                border-radius: 16px;
                font-size: 20px;
                font-weight: bold;
                padding: 8px;

                &.true {
                    background: #9BC87D;
                    border-color: #9BC87D;
                    color: #FFF;
                }

                & svg {
                    & path {
                        stroke-width: 3px;
                    }
                }
            }
        }
    }

    .meerButton {
        &>svg {
            transform: rotate(0deg);
            transition: transform 0.8s;
            transition-timing: ease-in-out;
        }

        &.lees {
            &>svg {
                transform: rotate(180deg);
            }
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }

    .upload__image {
        .fileIconBox {
            width: 60px;
            height: 60px;

            .fileIcon {
                width: 45px;
                height: 60px;
                margin: 0 auto;
                float: none;

                & div {
                    float: left;
                }

                .triangle2 {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0px 15px 15px 00px;
                    border-color: transparent #3c9caf #4CB0C4 transparent;
                }

                .triangle {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0px 15px 15px 00px;
                    border-color: transparent transparent #3cc8e3 transparent;
                }

                .left2 {
                    background: #4CB0C4;
                    width: 30px;
                    height: 30px;
                }

                .bottom {
                    background: #4CB0C4;
                    width: 45px;
                    height: 30px;
                    padding-top: 8px;
                    font-size: 13px;
                    font-weight: 600;
                    text-align: center;
                    letter-spacing: -1px;
                    line-height: 1;
                    color: white;
                }
            }
        }

        input[type=checkbox] {
            display: none;

            &+button {
                position: relative;

                &:before {
                    content: '';
                    -webkit-appearance: none;
                    background-color: transparent;
                    border: 1px solid #222222;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    border-radius: 4px;
                }
            }

            &:checked+button:before {
                border: 1px solid var(--checkboxColor);
                background-color: var(--checkboxColor);
            }

            &:checked+button:after {
                content: '';
                display: block;
                position: absolute;
                top: 5px;
                left: 9px;
                width: 7px;
                height: 11px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                z-index: 1;
            }
        }

        .text__ellipsed {
            min-height: 22px;
            display: block;
            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: default;
        }

        .text__ellipse {
            min-height: 22px;
        }

        .input__file {
            height: 10px;
        }

        .upload__btn {
            background-color: #FFF;
            color: #222222;
            border: 1px solid #707070;

            &:hover {
                background-color: #FFF;
                color: #222222;
                border: 1px solid #707070;
            }

            &:disabled {
                background-color: #FFF;
                color: #222222;
                border: 1px solid #707070;
                opacity: 0.5;
            }
        }

        .online__btn {
            background-color: #4CB0C4;
            color: #FFF;

            &:disabled {
                background-color: #4CB0C4;
                color: #FFF;
                opacity: 0.5;
            }
        }

        .img_title {
            p {
                font-style: italic;
                text-decoration: underline;
            }
        }

        .infoimgs {
            vertical-align: sub;

            svg {
                width: 18px;
                height: 18px;
            }
        }

        .file__delete {
            min-width: 13px;
            max-width: 13px;
        }
    }

    .alertBox {
        margin-top: 16px;
        padding: 24px;
        border-radius: 8px;

        .msg {
            font-size: 14px;
        }

        &.error {
            color: red;
            background-color: #fff4f4;
            border: 1px solid red;
        }
    }

    .qtyTable1 {
        display: grid;
        font-size: 14px;
        grid-gap: 0;
        grid-template-columns: auto;
        margin: 0;
        padding: 0;
        width: 100%;
        max-width: 602px;

        .table-body,
        .table-head {
            display: grid;
            grid-template-columns: auto;
            width: 100%;
        }

        .table-body {
            .table-row {
                &:hover {
                    background-color: #f5f5f5;

                    &:after {
                        content: "";
                        position: absolute;
                        height: 100%;
                        left: 0;
                        bottom: 0;
                        background-color: #f5f5f5;
                    }
                }
            }
        }

        .table-row {
            display: grid;
            grid-gap: 0;
            grid-template-columns: 41% 34% 25%;
            grid-template-rows: auto;
            grid-gap: 24px;
            padding: 0 8px;
            width: 100%;

            &.true {
                opacity: 0.5;

                .item-cell {
                    cursor: default;
                }
            }

            &.text-row {
                .item-cell {
                    padding: 4px 0;
                }
            }

            &:after {
                content: "";
                position: absolute;
                z-index: 0;
            }

            &-title {
                position: relative;

                &::after {
                    content: "";
                    width: calc(100% + 40px);
                    height: 1px;
                    background-color: #dbdbdb;
                    position: absolute;
                    left: 0;
                    bottom: 1px;
                }

                &:hover {
                    background-color: transparent;
                }

                .item-cell {
                    font: normal normal bold 15px/24px Poppins;
                    vertical-align: middle;
                    cursor: default;

                    &>i {
                        padding-right: 0px;
                    }
                }
            }

        }

        .item-cell {
            position: relative;
            z-index: 1;
            display: flex;
            font: normal normal 300 15px/24px Poppins;
            padding: 8px 0;
            vertical-align: middle;
            width: 100%;
            cursor: pointer;

            &>i {
                min-width: 72px;
                font-style: normal;
                padding-right: 4px;
            }

            &>.custom {
                .input__container {

                    width: 115px;
                    align-items: center;
                    border: 1px solid #dfdfdf;
                    height: auto;
                    background-color: #fff;
                    border-radius: 5px;
                    button{
                        display: flex !important;
                        position: static !important;
                        width: 50%;
   
                    }
                }
                .text-qty-increment{
                    border: 0 !important;
                    text-align: center !important;
                }
                button {
                    display: none;
                }
            }

            .hypen {
                min-width: 20px;
                text-align: right;
            }

            .hypen+span {
                display: none;
            }

            &:nth-child(2) {
                color: #52B80E;
                justify-content: flex-end;

                &>i {
                    text-align: right;
                }
            }

            &:nth-child(3) {
                display: none;
            }

            &:nth-child(4) {
                justify-content: flex-end;
            }

            .input__container {

                width: 115px;
                align-items: center;
                border: 1px solid #dfdfdf;
                height: 42px;
                background-color: #fff;
                border-radius: 5px;
                button{
                    display: flex !important;
                    position: static !important;

                }
            }
            .text-qty-increment{
                border: 0 !important;
                text-align: center !important;
            }

            button {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                mix-blend-mode: darken;
                z-index: 1;
                &:hover {
                    background-color: #f5f5f5;
                }
            }

            input[type="checkbox"] {
                display: none;
            }



            input[type="text"] {
                height: auto;
                padding: 4px;
                font-size: 15px;
                margin: 0 0px 0px 0px;
                border: 1px solid #CCCCCC;
                background-color: #FFFFFF;
            }

            input[type="checkbox"]+.span {
                width: 22px;
                height: 22px;
                display: flex;
                position: relative;
                margin: 1px;

                &:before {
                    content: '';
                    -webkit-appearance: none;
                    background-color: transparent;
                    border: 1px solid #CCCCCC;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    width: 22px;
                    height: 22px;
                    display: flex;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    border-radius: 4px;
                }

                +span {
                    margin-left: -3px;
                }
            }

            input[type="checkbox"]:checked+.span:before {
                border: 1px solid var(--checkboxColor);
                background-color: var(--checkboxColor);
            }

            input:checked+.span:after {
                content: '';
                display: block;
                position: absolute;
                top: 3px;
                left: 8px;
                width: 7px;
                height: 14px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                z-index: 1;
            }
        }
    }

    .qtyTable {
        table {
            border: 0px solid #ccc;
            border-collapse: collapse;
            margin: 0;
            padding: 0;
            width: 100%;
            max-width: 602px;

            tr {
                &:hover {
                    td {
                        background-color: #f5f5f5;
                    }
                }

                &.true {
                    opacity: 0.5;

                    button {
                        cursor: default;
                    }
                }

                th {
                    font: normal normal bold 15px/22px Poppins;
                    padding: 0 8px;
                    height: 38px;
                    vertical-align: middle;
                    border-bottom: 1px solid #dbdbdb;
                    z-index: 1;
                    line-height: 38px;

                    &:nth-child(1) {
                        margin-left: 38px;
                        display: table-cell;
                        margin-bottom: -1px;
                        line-height: 38px;
                        width: calc(100% - 38px);
                    }

                    &:nth-child(2) {
                        color: #52B80E;
                        max-width: 80px;
                        text-align: right;
                    }

                    &:nth-child(3) {
                        display: none;
                    }

                    &:nth-child(4) {
                        text-align: right;
                    }
                }

                td {
                    font: normal normal 300 15px/22px Poppins;
                    padding: 0 8px;
                    height: 38px;
                    vertical-align: middle;
                    cursor: pointer;

                    &:nth-child(1) {
                        max-width: 200px;
                    }

                    &:nth-child(2) {
                        color: #52B80E;
                        max-width: 80px;
                        text-align: right;
                    }

                    &:nth-child(3) {
                        display: none;
                    }

                    &:nth-child(4) {
                        text-align: right;
                    }

                    button {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        right: 0;
                        mix-blend-mode: darken;

                        &:hover {
                            background-color: #f5f5f5;
                        }
                    }

                    input[type="checkbox"] {
                        display: none;
                    }

                    input[type="text"] {
                        height: auto;
                        padding: 7px 8px;
                        margin: 0 -4px;
                        border: 1px solid #CCCCCC;
                        background-color: #FFFFFF;

                        &::-ms-input-placeholder {
                            /* Edge 12-18 */
                            // font-style: italic;
                        }

                        &::placeholder {
                            // font-style: italic;
                        }
                    }

                    input[type="checkbox"]+.span {
                        &:before {
                            content: '';
                            -webkit-appearance: none;
                            background-color: transparent;
                            border: 1px solid #CCCCCC;
                            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                            padding: 9px;
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            cursor: pointer;
                            margin: 0 4px 0 0;
                            border-radius: 4px;
                        }

                        +span {
                            margin-left: -3px;
                        }
                    }

                    input[type="checkbox"]:checked+.span:before {
                        border: 1px solid var(--checkboxColor);
                        background-color: var(--checkboxColor);
                    }

                    input:checked+.span:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 13px;
                        left: 14px;
                        width: 7px;
                        height: 11px;
                        border: solid #fff;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                        z-index: 1;
                    }
                }
            }
        }
    }

    .variantcolorPicker {
        .variantCard {

            &>.content {
                height: 240px ;
            }


        }

        .colorChoose {
            max-width: 200px;
            width: 100%;

            h4 {
                font-size: 15px;
                line-height: 23px;
                font-weight: bold;
            }

            &>a {
                font: normal normal 300 15px/23px Poppins;
                color: var(--themeColor);
                width: 100%;
            }

            .colorInput {
                padding: 8px 10px;
                border: 1px solid #CCCCCC;
                border-radius: 10px;

                .colorName {
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 300;
                }

                .colorBox {
                    position: relative;
                    overflow: hidden;
                    min-width: 20px;
                    height: 20px;

                    border: 1px solid rgb(112, 112, 112);

                    &.noColor {
                        &:after {
                            content: '';
                            position: absolute;
                            width: 40px;
                            height: 40px;
                            top: -10px;
                            left: -10px;
                            border-width: 20px;
                            border-style: solid;
                            border-top-color: red;
                            border-left-color: blue;
                            border-right-color: green;
                            border-bottom-color: orange;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }

        .addColor {
            color: var(--lightColor);
        }

        .qtyChoose {
            width: 100%;

            h4 {
                font-size: 15px;
                line-height: 23px;
                font-weight: bold;
            }

            input {
                padding: 8px 16px;
                border: 1px solid #CCCCCC;
                border-radius: 10px;
                font-size: 15px;
                line-height: 20px;
                font-weight: 300;
                max-width: 100%;
                width: 100%;
                outline: transparent;
            }
        }

        .MuiButtonBase-root {
            padding: 4px;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        .variantCard {
            width: 100%;
            max-width: 198px;
            padding: 0 6px;

            &>.content {
                max-width: 186px;
                width: 100%;
                max-height: 240px;
                height: 100%;
                border: 1px solid #CCCCCC;
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

                & .imgInfo {
                    img {
                        mix-blend-mode: darken;
                    }
                }

                &>.varientInfo {
                    // height: 96px;
                    overflow: hidden;

                    &.auto {
                        height: auto;
                        flex: 1 1 0%;
                            h4{
                                color: #888888;
            
                            }
             
                        & .label {
                            height: auto;
                            text-align: center;
                        }
                    }

                    & .label {
                        font-size: 15px;
                        height: 23px;
                        line-height: 23px;
                        font-weight: bold;
                        overflow: hidden;
                    }

                    & .labelOnly {
                        font-size: 15px;
                        line-height: 23px;
                    }

                    & .qty {
                        font-size: 15px;
                        font-weight: 300;
                        color: #9BC87D;
                    }

                    & .time {
                        font-size: 15px;
                        font-weight: normal;
                        color: #FF8800;
                        position: relative;
                        z-index: 1;

                        & .icon {
                            height: 20px;
                            width: 20px;
                            display: inline-block;

                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                &:hover {
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.08);
                }

                &:disabled {
                    &:hover {
                        box-shadow: none;
                    }
                }

                &.active {
                    border-color: #9BC87D;

                    &:hover {
                        box-shadow: none;
                    }

                    &:before {
                        background-color: #9BC87D1A;
                    }
                }
            }

            &:nth-child(2n+1) {
                justify-content: flex-start;
                margin-left: -6px;
            }

            &:nth-child(2n+2) {
                justify-content: flex-end;
                margin-right: -6px;
            }
        }
    }

    .variantCardList {

        // max-width: 396px;
        &.gap-y-4 {
            column-gap: 14px;
            margin-bottom: 12px;
        }

        &>.flex {
            &>p {
                font: normal normal 300 13px/23px Poppins;
            }

            &>input {
                padding: 8px 16px;
                border: 1px solid #CCCCCC;
                border-radius: 10px;
                font-size: 15px;
                line-height: 20px;
                font-weight: 300;
                max-width: 100%;
                width: 100%;
            }
        }

        .manualSize {
            p {
                font-size: 15px;
            }

            .numberButton {
                button {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    cursor: pointer;

                    &:hover {
                        background: #F5F5F5;
                    }
                }

                h5 {
                    font-size: 14px;
                    font-weight: bold;
                    min-width: 30px;
                }

                input {
                    background: #FFFFFF;
                    line-height: 21px;
                    padding: 0;
                    height: 100%;
                    min-width: 40px;
                    text-align: center;
                }
            }
        }

        .variantCard {
            width: calc(50% - 7px);
            max-width: 186px;

            // padding: 0 6px;
            // margin-bottom: 8px;
            &>.content {
                max-width: 186px;
                width: 100%;
                max-height: 280px;
                height: 100%;
                border: 1px solid #CCCCCC;
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

                & .imgInfo {
                    img {
                        mix-blend-mode: multiply;
                    }
                }

                & .txtInfo {
                    font-size: 15px;
                    font-weight: bold;
                    line-height: 23px;
                }

                &>.varientInfo {
                    // max-height: 96px;
                    overflow: hidden;

                    &.auto {
                        height: auto;
                        flex: 1 1 0%;
                            h4{
                                color: #888888;
            
                            }

                        & .label {
                            height: auto;
                            text-align: center;
                        }
                    }

                    & .label {
                        font-size: 15px;
                        height: calc(100% - 24px);
                        line-height: 23px;
                        font-weight: bold;
                        overflow: hidden;
                        word-break: break-word;
                        text-align: center;
                        -webkit-hyphens: auto;
                        -moz-hyphens: auto;
                        -ms-hyphens: auto;
                        hyphens: auto;
                        display: block;

                        &+div.middle {
                            background: #fff;
                            position: relative;
                            height: 100%;

                            &:before {
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                            }
                        }
                    }

                    & .labelOnly {
                        font-size: 15px;
                        line-height: 23px;
                    }

                    & .qty {
                        font-size: 15px;
                        font-weight: 300;
                        color: #9BC87D;
                    }

                    & .time {
                        font-size: 15px;
                        font-weight: normal;
                        color: #FF8800;
                        position: relative;
                        z-index: 1;

                        & .icon {
                            height: 12px;
                            width: 12px;
                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                &:hover {
                    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.08);
                }

                &:disabled {
                    cursor: default;
                    opacity: 0.8;

                    &:hover {
                        box-shadow: none;
                    }
                }

                &.active {
                    border-color: #9BC87D;

                    &:hover {
                        box-shadow: none;
                    }

                    &:before {
                        background-color: #9BC87D1A;
                    }

                    &>.varientInfo {
                        & .label {
                            &+div.middle {
                                &:before {
                                    background-color: #9BC87D1A;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .sampleForm {
        background: #F5E8EF 0% 0% no-repeat padding-box;
        border-radius: 20px;

        &>.content {
            padding: 20px 32px 20px 20px;

            .refund-icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                padding-top: 1rem;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            h3 {
                font: normal normal bold 18px/27px Poppins;
            }

            p {
                font: normal normal normal 15px/24px Poppins;
                display: flex;
                flex-direction: column;

                span {
                    text-decoration: underline;
                }

                & b {
                    & span {
                        display: block;
                        text-decoration: underline;
                        width: max-content;
                    }
                }
            }

            li {
                font: normal normal 300 15px/24px Poppins;
            }

            input[type="checkbox"] {
                display: none;

                &+button {
                    position: relative;
                    margin-top: 40px;

                    &:before {
                        content: '';
                        -webkit-appearance: none;
                        background-color: transparent;
                        border: 1px solid #707070;
                        padding: 11px;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        background-color: #FFFFFF;
                        border-radius: 4px;
                    }
                }

                &:checked {
                    &+button:before {
                        border: 1px solid var(--checkboxColor);
                        background-color: var(--checkboxColor);
                    }

                    &+button:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 5px;
                        left: 9px;
                        width: 7px;
                        height: 11px;
                        border: solid #fff;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                        z-index: 1;
                    }
                }
            }
        }
    }
.deliverytime_red{
    background: #ffeded !important;
    color:  #e13939 !important;
}
    .deliveryTime {
        background: #F5EDE7 0% 0% no-repeat padding-box;
        border-radius: 20px;

        &>.content {
            padding: 20px 32px 20px 20px;

            h3 {
                font: normal normal bold 18px/27px Poppins;
            }

            p {
                font: normal normal normal 15px/24px Poppins;
            }

            .icon {
                width: 24px;
                height: 24px;
                color: #dbbda7;
                margin-top: 2px;
            }
        }
    }

    .action {
        button {
            text-decoration: underline;
        }
    }

    .priceFinal {
        font: normal normal bold 26px/39px Poppins;
    }

    .priceDetails {
        background: #F3F6F6 0% 0% no-repeat padding-box;
        border-radius: 20px;

        & table {
            width: 100%;
            font: normal normal 300 15px/23px Poppins;

            tr {
                td {
                    padding: 4px;

                    // text-transform: capitalize;
                    &:nth-child(2) {
                        text-align: right;
                    }

                    &>b {
                        font-size: 16px;

                        &.normal {
                            font-size: 15px;
                        }
                    }

                    .green {
                        color: #9BC87D;
                    }
                }
            }
        }
    }

    .action__blocks {
        row-gap: 1.3rem;
        button {
            &:first-child {
                background-color: #FFCC00;
                color: #222222;

                span {
                    padding-left: 0px;
                }

                &:hover {
                    background-color: #F2C200;

                    span {
                        transform: translate(4px);
                    }
                }
            }

            &:nth-child(2) {
                &:hover {
                    background-color: #4E4E4E;
                }
            }
        }

        .share {
            >.flex {
                svg {
                    width: 20.61px;
                    height: 19.72px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .arrow {
                position: relative;
                width: 1px;
                height: 20px;

                &::before {
                    top: 0px;
                    left: 0px;
                    bottom: 0px;
                    margin: auto;
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 100%;
                    background-color: #222222;
                }
            }
        }
    }

    .ups__content {
        >div {
            .flex {
                svg {
                    width: 18.53px;
                    min-width: 18.53px;
                    min-height: 15px;
                    height: 15px;
                }
            }
        }
    }

    .additionalSubGroupList {
        padding: 20px 0;
        gap: 10px;

        .MuiAccordionSummary-root {
            background: #688388;
            border-radius: 20px;
            padding: 8px 20px;

            .MuiAccordionSummary-content {
                margin: 0;
                font-size: 18px;
                font-weight: bold;
                line-height: 27px;
                color: #FFFFFF;
            }

            .MuiAccordionSummary-expandIconWrapper {
                color: #FFFFFF;
            }
        }

        .subGroupItem {
            h3 {
                font-size: 15px;
                line-height: 22px;
                font-weight: bold;

                span {
                    color: var(--themeColor);
                    cursor: pointer;
                }
            }

            h4 {
                font-size: 15px;
                line-height: 23px;
                font-weight: 300;
                color: #888888;
            }

            .cardItem {
                .imgCard {
                    width: 107px;
                    height: 107px;
                    position: relative;
                }

                .cardAction {
                    button {
                        min-width: 105px;
                        max-width: 105px;
                        min-height: 107px;
                        border: 1px solid #CCCCCC;
                        border-radius: 20px;
                        padding: 12px 10px;
                        position: relative;
                        word-break: break-word;
                        hyphens: auto;

                        &:before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            border-radius: 20px;
                        }

                        &:hover {
                            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.08);
                        }

                        &.active,
                        &.none {
                            border-color: #9BC87D;

                            &:hover {
                                box-shadow: none;
                            }

                            &:before {
                                background-color: #9BC87D1A;
                            }
                        }

                        h3 {
                            font-size: 15px;
                            line-height: 23px;
                            font-weight: bold;
                            text-align: center;
                        }

                        h4 {
                            font-size: 14px;
                            line-height: 15px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }

    .has-image {
        height: 240px !important;
      }
      
      .no-image {
        height: 160px !important;
      }

      .blender_class {
        mix-blend-mode: unset !important;
      }

      .imagehidden{
        display: none;
      }
      
      .imagehiddenalign{
        margin-top: 50%;
        margin-bottom: 30%;
      }
}

@media screen and (max-width:640px) {
    .productVariant {
        
        .qtyTable1 {
            .input__container{
                width: 90px !important;
            }
            .table-row {
                width: calc(100% - 40px);
                

                &:hover {
                    &:after {
                        width: calc(100% + 40px);
                    }
                }
            }
        }

    }
}

/** medium screen **/
@media screen and (min-width: 640px) {
    .productVariant {

        .qtyTable_popup {
            .table-row {

                &-title {
                    &::after {
                        width: calc(100% - 40px);
                        // margin-left: 32px;
                    }

                    .item-cell {
                        &:nth-child(1) {
                            padding-left: 32px;
                        }

                        &:nth-child(2) {
                            color: #52B80E;
                            justify-content: flex-end;
                        }
                    }
                }
            }

        }

        .qtyTable1 {
            .table-row {
                grid-template-columns: 164px 110px auto 148px;

                &-title {
                    &::after {
                        width: calc(100% - 12px);
                    }

                    .item-cell {
                        &:nth-child(1) {
                            padding-left: 0px !important;
                        }

                        &:nth-child(2) {
                            color: #52B80E;
                            justify-content: flex-end;
                        }
                    }
                }
            }

            .item-cell {
                &:nth-child(3) {
                    display: flex;
                }
            }
        }

        .qtyTable {
            table {
                tr {
                    th {
                        &:nth-child(1) {
                            position: relative;
                            border-width: 0;
                            display: inline-block;
                            padding-left: 0px;

                            &::after {
                                content: "";
                                position: absolute;
                                width: 100%;
                                bottom: -1px;
                                left: 0;
                                height: 1px;
                                background-color: #dbdbdb;
                            }
                        }

                        &:nth-child(3) {
                            display: table-cell;
                        }
                    }

                    td {
                        &:nth-child(3) {
                            display: table-cell;
                        }
                    }
                }
            }
        }

        .variantCardList {
            .variantCard {
                min-width: 186px;
            }
        }
    }
}

@media screen and (min-width:1292px) {
    .productVariant .variantCardList.gap-y-4 {
        column-gap: 22px;
        row-gap: 22px;
    }
}

@media screen and (min-width:1250px) {
    .productVariant .variantCardList.gap-y-4 {
        column-gap: 11px;
        row-gap: 11px;
    }
}

@media screen and (min-width:768px) {
    .productVariant .variantcolorPicker .qtyChoose {
        max-width: 128px;
    }
}