.privacy__policy {
    .page__container {
        button {
            height: 50px;

            svg {
                min-width: 15px;
            }
        }

        a {
            color: var(--themeColor);
            font-weight: 300;
        }

        h1 {
            font-size: 24px;
            line-height: 1.75rem;
            font-weight: 700;
            padding-bottom: 24px;
        }

        h6,h2,h5 {
            font-size: 20px;
            line-height: 1.75rem;
            font-weight: 700;
            padding-top: 2rem;
            padding-bottom: 16px;
        }

        p{
            line-height: 1.75rem;
            &:last-child{
                padding-bottom: 0px;
            }
        }
        
        a,span{
            line-height: 1.75rem;
        }

        ul,ol{
            list-style-type: inherit;
            margin-left: 20px;
            li{
                padding-bottom: 10px;
                &:last-child{
                    padding-bottom: 0px;
                }
            }
        }
    }
}