.overview {
    .product__info {
        .backgroundBox{
            min-height: 154px;
        }
        .recent__info {
            .image {
                background-color: #F5F5F5;
                min-width: 80px;
                width: 80px;
                height: 80px;
                border-radius: 10px;
                padding: 5px;

                img {
                    mix-blend-mode: multiply;
                }
            }

            .view__product {
                border: 1px solid #ffcc00;
                background-color: #ffcc00;
                padding: 0px 12px;
                font-size: 13px;
                min-height: 39px;
                line-height: 1;
                border-radius: 10px;
                white-space: nowrap;
                width: 74px;

                &:hover {
                    background-color: var(--lightColor);
                    color: #fff;
                    border: 1px solid var(--lightColor);
                }

                svg {
                    font-size: 10px;
                    transform: rotate(90deg);
                }
            }
        }
    }
    .news__account{
        .backgroundBox{
            min-height: 201px;
        } 
    }
}

@media(min-width:768px){
    .overview {
        .product__info {
            .backgroundBox{
                min-height: 271px;
            }
        }
    } 
}