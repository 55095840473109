.disclaimer {
  .disclaimer__highlight {
    border: 1px solid #ffc601;
    background-color: #fef9e7;
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 1rem;
  }.link{
    &:hover{
      text-decoration: underline;
    }
  }
  h5{
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 2rem;
  }
  h6{
    padding:1rem 0px 1rem 0px;
    font-size: 20px;
    font-weight: 600;
  }
  p{
    padding: 0rem 0px 1rem 0px;
    font-size: 15px;
    line-height: 1.5rem;
  }
  h4{
    padding: 0rem 0px 1rem 0px;
    font-size: 15px;
    font-weight: 500;
  }
}
.breadcrums{
    font-size: 13px;
   .symbol{
    color: var(--themeColor);
   }
    .static__page{
        color: #cccccc;
    }
}
@media screen and (min-width:1024px){
    .disclaimer{
        .disclaimer__highlight{
            width: 80%;
           }
    }
   
}
