.cartpage {
  .middle {
    vertical-align: middle;
  }

  .sidebar__cartpage {
    .priceDetails {
      .green {
        color: #9bc87d;
      }
    }

    .button__info {
      .mondu__img {
        width: 83px;
      }

      button {
        height: 62px;
        background-color: #ffcc00;
        color: #222222;
      }

      .payment__img {
        width: 284px;
      }
    }

    table tr {
      line-height: 1.8rem;
      font-size: 15px;

      td:nth-child(2) {
        text-align: right;
      }
    }

    .coupon__section {
      border-bottom: 1px solid #cccccc;

      .input__control {
        input {
          border: 1px solid #cccccc;
          background-color: #fff;
          color: #222222;
        }
      }

      .coupon__button {
        height: 50px;
      }
    }
  }

  .product__img a {
    width: 100px;
    height: 113px;

    img {
      mix-blend-mode: multiply;
      object-fit: contain;
    }
  }

  .cart__details__parent {
    border-bottom: 1px solid #cccccc;
    opacity: 1;

    .social__detail {
      a {
        justify-content: flex-start;
        width: calc(100% - 50px);
      }
    }
  }

  .details__block {
   
    .upload__imgage__section {
      .img_title {
        span {
          width: max-content;
        }
      }
      .text-ellipse {
        a {
          max-width: 350px;
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          line-clamp: 1;
          box-orient: vertical;
          text-decoration: underline;
          min-height: 22px;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }

      .upload__input {
        height: 10px;
        width: 10px;
        display: none;
      }
    }

    .up__arrow svg {
      width: 18px;
      height: 18px;
    }

    .hide__mobile {
      display: none;
    }

    .delete__img {
      width: 16px;
      height: 18px;
      cursor: pointer;
    }

    .input__container_loader {
      width: 115px;
      border: 1px solid #dfdfdf;
      height: 42px;
      background-color: #fff;
      border-radius: 5px;
    }

    .input__container {
      width: 115px;
      align-items: center;
      border: 1px solid #dfdfdf;
      height: 42px;
      background-color: #fff;
      border-radius: 5px;

      input {
        width: 50px;
        text-align: center;
        height: 40px;
        background: #fff;
        padding: 0px;
      }

      button {
        width: 30px;
        height: 40px;
        background-color: #fff;
        color: #222222;
        border: none;
        cursor: pointer;
      }
    }
  }

  .icon__block {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 568px) {
  .none {
    display: none !important;
  }
  .wrap-link a {
    word-wrap: break-word;
    max-width: 210px !important;
  }
}
@media screen and (max-width: 1024px) {
  .cart__details {
    margin-bottom: 1.5rem;
  }
}
@media screen and (max-width: 400px) {
  .social__detail {
    a {
      width: 160px !important;
    }
  }
}

@media screen and (max-width: 360px) {
  .uploadButton {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 768px) {
  .cartpage__container {
    .cart__details {
     
      .product__img a {
        background-color: #f5f5f5;
        min-width: 220px;
        width: 220px;
        height: 220px;
        border-radius: 20px;
        padding: 1rem;
      }
    }
  }
}

@media screen and (min-width: 1250px) {
  .cartpage {
    .xxl-w-1\/2 {
      max-width: 40%;
    }
    .upload__imgage__section{
      .text-ellipse{
        a{
          max-width: 550px !important;
        }
      }
    }

    .cartpage__container {
      .cart__details {
        .details__block {
          .hide__mobile {
            display: block;
          }
        }
      }

      .sidebar__cartpage {
        min-width: 340px;
      }
    }
  }
}
@media  (min-width: 1025px) and (max-width: 1250px){
  .cartpage {
   
    .upload__imgage__section{
      .text-ellipse{
        a{
          max-width: 350px !important;
        }
      }
    }

    
  }
}
@media  (min-width: 768px) and (max-width: 998px){
  .cartpage {
   
    .upload__imgage__section{
      .text-ellipse{
        a{
          max-width: 500px !important;
        }
      }
    }

    
  }
}

@media screen and (max-width: 992px) {
  .mobile_view_Artikelnummer{
    max-width: 300px;
  }
  
}


@media screen and (max-width: 500px) {
  .mobile_view_Artikelnummer{
    max-width: 192px;
  }
  
}


.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}