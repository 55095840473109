.home__topcategories {
    .title__section {
        font-size: 24px;
    }

    .navigation-wrapper {
        position: relative;

        .arrow {
            position: absolute;
            display: none;
            width: 72px;
            height: 72px;
            top: 60px;

            &.left__arrow {
                left: 24px;
            }

            &.right__arrow {
                right: 24px;
            }

            &.arrow--disabled {
                display: none;
            }
        }
    }

    .categoryItem {
        min-width: 144px;
        max-width: 144px;

        .categoryBlock {
            margin: 0 0 0 16px;
        }
    }

    .categoryItem {
        .categoryBlock {
            .ImageBlock {
                width: 128px;
                min-width: 128px;
                max-width: 128px;
                height: 128px;
                max-height: 128px;
                min-height: 128px;
                background-color: #f5f5f5;
                clip-path: circle(50%);
                border-radius: 50%;
                margin: auto;
                &>a {
                    width: 140px;
                    height: 140px;
                    display: block;
                }
                img {
                    -webkit-transition: -webkit-transform 0.3s ease; /* Safari */
                    -moz-transition: -moz-transform 0.3s ease; /* Older Firefox */
                    -o-transition: -o-transform 0.3s ease; /* Older Opera */
                    transition: transform 0.3s ease; /* Modern browsers */
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    mix-blend-mode: multiply;
                    position: relative;
                    z-index: 0;
                }

                &:hover img  {
                        -webkit-transform: scale(1.25);
                        -moz-transform: scale(1.25);
                        -o-transform: scale(1.25);
                        transform: scale(1.25);
                    }
            }

            h2 {
                font-size: 14px;
                font-weight: bold;

                a {
                    min-height: 36.75px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                }
            }
        }
    }
}

@media screen and (min-width: 1025px) {
    .home__topcategories {
        .navigation-wrapper {
            .arrow {
                display: block;
            }
        }

        .categoryItem {
            min-width: 208px;
            max-width: 208px;

            .categoryBlock {
                margin: 0 0 0 16px;

                .ImageBlock {
                    width: 192px;
                    min-width: 192px;
                    max-width: 192px;
                    height: 192px;
                    max-height: 192px;
                    min-height: 192px;

                    &>a {
                        width: 192px;
                        height: 192px;
                    }
                }

                h2 {
                    a {
                        min-height: 56px;
                    }
                }
            }
        }

        .categoryItem {
            .categoryBlock {
                h2 {
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }
    }

    .leftTransparentSlider {
        height: 100%;
        width: 100%;
        background-color: transparent;
        position: absolute;
        left: 100%;
        z-index: 10;
    }

    .rightTransparentSlider {
        height: 100%;
        width: 100%;
        background-color: transparent;
        position: absolute;
        right: 100%;
        top: 0px;
    }

    .slick__arrow {
        z-index: 11;
    }
}

@media screen and (min-width: 1250px) {
    .home__topcategories {
        .keen-slider__slide .product__grid {
            margin: 0 0 0 24px;
        }
    }

    .navigation-wrapper {
        &:hover {

            &:after,
            &:before {
                background: rgba(255, 255, 255, 0.6);
            }
        }
    }

    .sliderLeftRightVisible {
        position: relative;
        z-index: 0;
        overflow: visible !important;

        &:after,
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            display: block;
            z-index: 10;
            background: rgba(255, 255, 255, 1);
            -webkit-transition: opacity 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            transition: opacity 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        }

        &:after {
            right: 100%;
        }

        &:before {
            left: 100%;
        }

        &.sliderFadeShow {

            &:after,
            &:before {
                opacity: 0;
            }
        }

        &.sliderFadeHide {

            &:after,
            &:before {
                opacity: 1;
            }
        }
    }

    .hover {

        &:after,
        &:before {
            background: rgba(255, 255, 255, 1);
            opacity: 1;
            transition: all 1.2s ease-in;
        }

        .sliderLeftRightVisible {

            &:after,
            &:before {
                opacity: 0;
            }
        }
    }
}

@media screen and (min-width: 1380px) {
    .home__topcategories {
        .navigation-wrapper {
            position: relative;

            .arrow {
                position: absolute;

                &.left__arrow {
                    left: -24px;
                }

                &.right__arrow {
                    right: -34px;
                }
            }
        }
    }
}