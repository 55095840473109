.sitemap__container{
    .search {
        position: relative;
        max-width: 600px;
    
        .searchbox {
          width: 100%;
          background: #fff;
          min-height: 48px;
          border: 1px solid #CCCCCC;
          border-radius: 24px;
          font-size: 15px;
    
          &::placeholder {
            font-style: italic;
            color: #b3b3b3;
          }
    
          &:focus,
          &:hover {
            border: 1px solid rgba(0, 0, 0, 0.04);
            background: #fff;
          }
        }
    
        .MuiButtonBase-root {
          position: absolute;
          right: 15px;
          width: 48px;
          height: 48px;
    
          .icons {
            width: 20px;
            height: 20px;
          }
        }
    
        &.focused {
          .searchbox {
            border: 1px solid #CCCCCC;
          }
        }
      }
    .hidden{
        display: none;
    }

    .category__list{
       p{
            font-size: 15px;
            padding: 0.25rem 0px;
            a{
                color:#222;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .product__list{
       ul li{
            font-size: 15px;
            // padding: 0.25rem 0px;
            a{
                color:#222;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}

.category-list {
  padding-left: 4rem;
}

.main-category{
  margin-top: 20px;
}