.myaccount__page {
    .myaccount__wrapper {
        background-color: #F5F5F5;
        .myaccount__main{
            flex-grow: 1;
        }
        .action {
            a {
                font-weight: 300;

                svg {
                    font-size: 10px;
                    transform: rotate(90deg);

                    line {
                        stroke-width: 1px;
                    }
                }
            }

            button {
                min-height: 50px;
                min-width: 200px;
                gap: 8px;

                svg {
                    font-size: 13px;
                    transform: rotate(90deg);

                    line {
                        stroke-width: 2px;
                    }
                }
            }

            &.underline {
                button {
                    min-height: inherit;
                    min-width: inherit;
                    background-color: unset !important;
                    color: #000000;
                    &:hover {
                        background-color: unset !important;
                    }
                    svg {
                        font-size: 10px;
                        transform: rotate(90deg);
    
                        line {
                            stroke-width: 1px;
                        }
                    }
                }
            }
        }

        input[type="checkbox"] {
            padding: 0px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 26px;
            height: 26px;
            border: 1px solid #F5F5F5;
            outline: none;
            cursor: pointer;
            background-color: #F5F5F5;
            transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
        }
    
        input[type="radio"] {
            padding: 0px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 25px;
            height: 25px;
            border: 1px solid #F5F5F5;
            border-radius: 50%;
            outline: none;
            cursor: pointer;
            background-color: #F5F5F5;
            transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
        }
    
        input[type="radio"]:checked {
            border: 4px solid #F5F5F5;
            background-color: var(--themeColor);
        }

        input[type="checkbox"]:checked {
            border: 1px solid var(--themeColor);
            background-color: var(--themeColor);
        }
    
        input[type="checkbox"]:checked {
            border: 3px solid #DFDFDF;
        }
    }
}