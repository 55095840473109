.faq {

  .search__suggestion__container__faq {
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    width: calc(100% - 36px);
    z-index: 1;
  }

  .faq__icon {
    font-size: 35px;
  }

  .faq__list {
    background-color: #f3f6f6;
  }

  .parent__card {
    column-gap: 2%;
  }

  .flex__card {
    background-color: #ffffff;
    border-radius: 20px;
    .questions.text__ellipse{
      min-height: 26px;
    }
  }

  .text__ellipse {
    min-height: 165px;
  }

  .search {
    position: relative;
    width: 100%;

    .searchbox {
      width: 100%;
      background: #fff;
      min-height: 48px;
      border: 1px solid #CCCCCC;
      border-radius: 24px;
      font-size: 15px;

      &::placeholder {
        font-style: italic;
        color: #b3b3b3;
      }

      &:focus,
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.04);
        background: #fff;
      }
    }

    .MuiButtonBase-root {
      position: absolute;
      right: 15px;
      width: 48px;
      height: 48px;

      .icons {
        width: 20px;
        height: 20px;
      }
    }

    &.focused {
      .searchbox {
        border: 1px solid #CCCCCC;
      }
    }
  }

  .MuiButtonBase-root {
    padding: 4px;
    width: 44px;
    height: 44px;

    svg {
      transform: scale3d(1, 1, 1);
      transition: all 0.12s cubic-bezier(0.4, 0, 0.6, 1);
    }

    &:active {
      svg {
        transform: scale3d(0.85, 0.85, 0.85);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .faq {
    .flex__card {
      width: 32%;

    }
  }
}

@media screen and (min-width: 1024px) {
  .faq {
    .search {
      width: 679px;
      margin: auto;
    }

    .search__res {
      width: calc(643px + 36px);
      margin: auto;
    }
  }
}

@media screen and (max-width:767px) {
  .faq {
    .faq__list {
      background-color: white !important
    }
  }
}