.vacancies__view {
    .banner_image_height{
            width: 100%;
            height: 400px;
            overflow: hidden;
            img{
    object-fit: cover;

            }
    }
    background-color: #F5F5F5;

    .breadcrumbimage__block {
        color: #fff;
    }
    .image__background{
        max-width: 880px;
        margin: auto;
    }

    .action {
        button {
            width: 100%;
            font-size: 15px;
            max-height: 51px;
            min-height: 51px;
            border-radius: 35px;

            &:first-child {
                background-color: var(--themeColor) !important;

                &:hover {
                    background-color: #222222 !important;
                }
            }
        }
    }

    .back__action {
        button {
            color: #999999;
            background-color: unset !important;

            &:hover {
                color: #999999;
                background-color: unset !important;
            }
        }
    }

    form {
        background-color: #fff;
        .file__upload__container{
            .file_name{
                color: #009ADE !important;
                font-style: italic;
            }
            .file__block{
                border: 1px solid #F5F5F5;
                height: auto;
                border-radius: 5px;
                #fileInput {
                    display: none;
                  }
                  .custom-label {
                    background-color: #EFEFEF;
                    color: #666666;
                    padding: 2px 8px;
                    cursor: pointer;
                    border: 1px solid #666666;
                    border-radius: 2px;
                  }
            }
        }
        .file__upload{
            input{
                &:hover{
                    border: 0px;
                }
            }
        }
        .file{
            p{
                img{
                    width: 18px;
                    height: 20px;
                }
            }
        }
    }

    .required {
        color: #CCCCCC;
    }

    textarea {
        height: 180px;
        resize: none;
    }

    .details {
        background-color: #fff;
        *{
            font-size: 15px;
            line-height: 26px;
        }
        
        svg {
            width: 18.53px;
            min-width: 18.53px;
            min-height: 15px;
            height: 15px;
            margin-top: 6px;
        }

        h4{
            font-size: 15px;
            font-weight: 600;
            padding-bottom: 3px;
        }

        h2{
            font-size: 24px;
            font-weight: 600;
        }

        ul{
            li{
                &::first-letter{
                    color: #00C407;
                    font-size: 20px;
                }
            }
        }
    }
}

@media(min-width: 420px){
    .vacancies__view {
    
        .action {
            button {
                width: 223px;
            }
        }
    }
}

@media(min-width: 640px){
    .vacancies__view {
        form {
            .file__upload__container{
                .file__block{
                    height: 50px;
                }
            }
        }
    }
}

@media(min-width: 768px){
    .vacancies__view {
    
        form {
            .file__upload__container{
                min-height: 98px;
            }
        }

    }
}