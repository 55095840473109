.faq__icon {
  font-size: 35px;
}

.faq__details {

  .toggle__arrow {
    input {
      width: 0px;
      height: 0px;
      border: 0px;
      pointer-events: none;
      outline: none;
      cursor: default;
    }
  }
}

.answers {
  #faqMyaccount {
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5rem;
  }

  ol {
    list-style-type: auto;
    margin-left: 17px;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  a {
    text-decoration: underline;
    vertical-align: top;
  }
}

.details_search__bar {
  .MuiButtonBase-root {
    right: 0px;
  }
}

.search__suggestion__container__faq {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #f5f5f5;
  border-radius: 5px;

  p {
    &:hover {
      background-color: #F5F5F5;
    }
  }
}


.faq {
  .detail__search {
    width: calc(100% - 0px);
  }
}

@media screen and (min-width: 1024px) {
  .faq {
    .detail__search {
      width: 679px;
    }
  }
}

@media screen and (max-width: 768px) {

  .user__sidebar_parent .backgroundBox {
    padding-top: 0px;
  }

  .user__sidebar_parent ul li:first-child {
    padding-left: 0px !important;
  }
}